import { ICattlePOFeatures } from './interface';
import { getFeatureFlagValue } from '../../common/featureFlags/helpers';

export const cattlePO: ICattlePOFeatures = {
    isBP860On_buyPOPickupDateRange: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP860_BUYPO_PICKUP_DATE_RANGE,
    ),
    isBP961_buyPOSlideDetail: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP961_BUYPO_SLIDE_DETAIL,
    ),
    isBP925_buyPOBuyer2: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP925_BUYPO_BUYER2,
    ),
    isBP865_buypoDoaField: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP865_BUYPO_DOA_FIELD,
    ),
    isBP1038_mobileScreenPageNotFoundIssue: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP1038_MOBILE_SCREEN_PAGE_NOT_ISSUE,
    ),
    isBP933_holdPaymentCheckboxBuyerForm: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP933_HOLD_PAYMENT_CHECKBOX_BUYER_FORM,
    ),
    isBP928_buyPOCutField: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP865_BUYPO_CUT_FIELD,
    ),
    isBP986_variableSettings: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP986_VARIABLE_SETTINGS,
    ),
    isBP934_BuyPOHoldPaymentCommentBox: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP934_BUYPO_HOLD_PAYMENT_COMMENTBOX,
    ),
    isBP927AddWeightStopFields: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP927_ADD_WEIGHT_STOP_FIELD,
    ),
    isBP929_FeeUnitCalculation: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP929_FEE_UNIT_CALCULATION,
    ),
    isBP926_BuyPOOrderNotes_RichTextEditor: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP926_RICH_TEXT_EDITOR,
    ),
};
